import { TextareaAutosize, styled } from '@mui/material'
import { CSSProperties } from 'react'

interface Props {
  id: string
  defaultValue?: string
  placeHolder?: string
  required?: boolean
  disabled?: boolean
  style?: CSSProperties
  onInput?: (v: string) => void
}

const StyledTextareaAutosize = styled(TextareaAutosize)(() => ({
  '& .Mui-disabled': {
    WebkitTextFillColor: 'black',
  },
  '& .MuiInputBase-input': {
    WebkitTextFillColor: 'black',
  },
}))

export default function KumocanSimpleTextareaAutosize(props: Props) {
  const {
    id,
    defaultValue = '',
    placeHolder = '',
    required = false,
    disabled = false,
    style: style = {},
    onInput,
  } = props

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onInput) {
      onInput(event.target.value)
    }
  }

  return (
    <StyledTextareaAutosize
      id={id}
      defaultValue={defaultValue}
      placeholder={placeHolder}
      required={required}
      disabled={disabled}
      style={style}
      onInput={handleInput}
      autoComplete="off"
    />
  )
}
