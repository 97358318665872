import { Schedule, Task } from '@kansei/ui'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { isSameMonth } from 'date-fns'
import React, { CSSProperties, useState, cloneElement } from 'react'

import { isToday, isFuture } from 'hooks/helper/date'

export type ListCalendarProps = {
  currentDate: Date
  schedules: Schedule[]
  onSelectTask: (schedule: Schedule, task: Task) => void
}

const ListCalendar: React.FC<ListCalendarProps> = ({
  currentDate,
  schedules,
  onSelectTask,
}) => {
  const [baseDate, setBaseDate] = useState(currentDate)

  // 日付を前後にスライドする関数
  const slideDate = (direction: 'prev' | 'next') => {
    const newDate = new Date(baseDate)
    if (direction === 'prev') {
      newDate.setDate(newDate.getDate() - 1)
    } else {
      newDate.setDate(newDate.getDate() + 1)
    }
    setBaseDate(newDate)
  }

  // 4日分の日付配列を生成
  const getDates = () => {
    const dates = []
    const startDate = new Date(baseDate)
    startDate.setDate(startDate.getDate() - 1) // 前日から開始

    for (let i = 0; i < 4; i++) {
      const date = new Date(startDate)
      date.setDate(startDate.getDate() + i)
      dates.push(date)
    }
    return dates
  }

  // 曜日の取得（日本語）
  const getJapaneseWeekday = (date: Date) => {
    const weekdays = ['日', '月', '火', '水', '木', '金', '土']
    return weekdays[date.getDay()]
  }

  const containerStyle: React.CSSProperties = {
    width: '100%',
    maxWidth: '400px',
    overflow: 'hidden',
  }

  const headerStyle: React.CSSProperties = {
    padding: '12px',
    backgroundColor: 'white',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
  }

  const arrowStyle: React.CSSProperties = {
    cursor: 'pointer',
    color: '#6b7280',
    fontSize: '32px',
  }

  const dateListStyle: React.CSSProperties = {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  }

  const dateItemStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    height: '120px',
    borderBottom: '2px dotted #e5e7eb',
  }

  const dayLeftStyle: React.CSSProperties = {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10%',
    height: '100%',
    textAlign: 'center',
    backgroundColor: '#f3f4f6',
  }

  const todayStyle = (isToday: boolean): CSSProperties => {
    return {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      backgroundColor: isToday ? 'black' : '#f3f4f6',
      width: '32px',
      height: '32px',
      color: isToday ? 'white' : 'black',
    }
  }

  const dayNumberStyle: React.CSSProperties = {
    fontSize: '12px',
    fontWeight: 'bold',
  }

  const weekdayStyle: React.CSSProperties = {
    fontSize: '8px',
  }

  const dayRightStyle = (date: Date): React.CSSProperties => {
    const color = isFuture(date) ? 'white' : '#e5e7eb'
    return {
      height: '100%',
      width: '90%',
      backgroundColor: color,
    }
  }

  const schedulesStyle = (date: Date): CSSProperties => {
    const color = isFuture(date) ? 'white' : '#e5e7eb'
    return {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '4px',
      padding: '8px 16px',
      height: 'calc(100% - 16px)',
      width: '90%',
      backgroundColor: color,
      opacity: isFuture(date) ? 1 : 0.5,
    }
  }

  const taskStyle = (isFuture: boolean, task: Task): CSSProperties => {
    const color = isFuture ? task.color : '#d1d5db'
    const borderColor = task.status === 20 ? 'red' : color
    return {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
      padding: '4px 4px 4px 16px',
      width: 'calc(100% - 26px)',
      height: 'calc(100% - 12px)',
      borderRadius: '4px',
      backgroundColor: color,
      border: `2px solid ${borderColor}`,
      color: 'white',
    }
  }

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        {`${baseDate.getFullYear()}年 ${baseDate.getMonth() + 1}月`}
      </div>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: '5px',
            left: '0',
            right: '0',
            textAlign: 'center',
            zIndex: 10,
          }}
        >
          <KeyboardArrowUpIcon
            style={arrowStyle}
            onClick={() => slideDate('prev')}
          />
        </div>

        <div
          style={{
            position: 'absolute',
            bottom: '5px',
            left: '0',
            right: '0',
            textAlign: 'center',
            zIndex: 10,
          }}
        >
          <KeyboardArrowDownIcon
            style={arrowStyle}
            onClick={() => slideDate('next')}
          />
        </div>

        <ul style={dateListStyle}>
          {getDates().map((date, index) => (
            <li key={index} style={dateItemStyle}>
              <div style={dayLeftStyle}>
                <div style={todayStyle(isToday(date))}>
                  <p style={dayNumberStyle}>{date.getDate()}</p>
                  <p style={weekdayStyle}>({getJapaneseWeekday(date)})</p>
                </div>
              </div>
              <div style={dayRightStyle(date)}>
                {schedules.map((schedule) => {
                  if (
                    isSameMonth(schedule.date, date) &&
                    schedule.date.getDate() === date.getDate()
                  ) {
                    return (
                      <div
                        key={schedule.date.getDate()}
                        style={schedulesStyle(date)}
                      >
                        {schedule.dayShiftTask ? (
                          <div
                            style={taskStyle(
                              isFuture(date),
                              schedule.dayShiftTask,
                            )}
                            onClick={() =>
                              onSelectTask(schedule, schedule.dayShiftTask!)
                            }
                          >
                            <div>
                              <p
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {schedule.dayShiftTask.time}
                              </p>
                              <p style={{ fontSize: '10px' }}>
                                {schedule.dayShiftTask.name}
                              </p>
                            </div>
                            {schedule.dayShiftTask.icon &&
                              cloneElement(
                                schedule.dayShiftTask
                                  .icon as React.ReactElement<any>,
                                {
                                  style: {
                                    fontSize:
                                      schedule.dayShiftTask &&
                                      schedule.nightShiftTask
                                        ? '24px'
                                        : '46px',
                                    color: 'rgba(255, 255, 255, 0.5)',
                                  },
                                },
                              )}
                          </div>
                        ) : null}
                        {schedule.nightShiftTask ? (
                          <div
                            style={taskStyle(
                              isFuture(date),
                              schedule.nightShiftTask,
                            )}
                            onClick={() =>
                              onSelectTask(schedule, schedule.nightShiftTask!)
                            }
                          >
                            <div>
                              <p
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {schedule.nightShiftTask.time}
                              </p>
                              <p style={{ fontSize: '10px' }}>
                                {schedule.nightShiftTask.name}
                              </p>
                            </div>
                            {schedule.nightShiftTask.icon &&
                              cloneElement(
                                schedule.nightShiftTask
                                  .icon as React.ReactElement<any>,
                                {
                                  style: {
                                    fontSize:
                                      schedule.dayShiftTask &&
                                      schedule.nightShiftTask
                                        ? '24px'
                                        : '46px',
                                    color: 'rgba(255, 255, 255, 0.5)',
                                  },
                                },
                              )}
                          </div>
                        ) : null}
                      </div>
                    )
                  } else {
                    return null
                  }
                })}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ListCalendar
