import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ListAltIcon from '@mui/icons-material/ListAlt'
import React, { CSSProperties, useState } from 'react'

export type Props = {
  onSelectCalendar: (bool: boolean) => void
}

const TabComponent: React.FC<Props> = ({ onSelectCalendar }) => {
  const [activeTab, setActiveTab] = useState(0)

  const iconStyle: CSSProperties = {
    fontSize: '32px',
    fontWeight: 'bold',
  }

  const tabs = [
    { id: 0, icon: <ListAltIcon style={iconStyle} />, label: '直近' },
    { id: 1, icon: <CalendarMonthIcon style={iconStyle} />, label: '月間' },
  ]

  const containerStyle: CSSProperties = {
    display: 'flex',
    gap: '4px',
    padding: '0 8px',
    maxWidth: '400px',
    borderBottom: '2px solid #14b7ae',
  }

  const tabStyle = (isActive: boolean): CSSProperties => ({
    flex: 1,
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2px',
    fontWeight: 'bold',
    fontSize: '20px',
    transition: 'all 0.2s ease',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: isActive ? '#14b7ae' : '#e5e7eb',
    color: isActive ? '#ffffff' : '#14b7ae',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  })

  const changeTab = (tabId: number) => {
    setActiveTab(tabId)
    onSelectCalendar(tabId === 1)
  }

  return (
    <div style={containerStyle}>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => changeTab(tab.id)}
          style={tabStyle(activeTab === tab.id)}
        >
          {tab.icon}
          <span>{tab.label}</span>
        </button>
      ))}
    </div>
  )
}

export default TabComponent
