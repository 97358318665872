import { TextField, styled } from '@mui/material'
import { CSSProperties } from 'react'
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form'

interface Props {
  id: string
  name?: string
  register?: UseFormRegisterReturn
  label?: string
  required?: boolean
  disabled?: boolean
  type?: 'text' | 'number' | 'decimal' | 'datetime-local' | 'password'
  fullWidth?: boolean
  valiant?: 'standard' | 'outlined' | 'filled'
  errors?: FieldErrors
  size?: 'small' | 'medium'
  style?: CSSProperties
  onInput?: (v: string) => void
  multiline?: boolean
}

const StyledTextField = styled(TextField)(() => ({
  '& .Mui-disabled': {
    WebkitTextFillColor: 'black',
  },
  '& .MuiInputBase-input': {
    WebkitTextFillColor: 'black',
  },
}))

export default function KanseiTextField(props: Props) {
  const {
    id,
    label = '',
    required = false,
    fullWidth = true,
    valiant = 'outlined',
    disabled = false,
    type = 'text',
    register,
    errors = {},
    size = 'small',
    style: style = {},
    onInput,
    multiline = false,
  } = props

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onInput) {
      onInput(event.target.value)
    }
  }

  const errorMessage = errors[id]?.message

  return (
    <StyledTextField
      name={id}
      id={id}
      label={label}
      required={required}
      disabled={disabled}
      type={type}
      fullWidth={fullWidth}
      variant={valiant}
      {...register}
      error={!!errorMessage}
      helperText={errorMessage?.toString()}
      size={size}
      sx={style}
      onInput={handleInput}
      autoComplete="off"
      multiline={multiline}
      inputProps={
        type === 'decimal'
          ? { step: 'any' }
          : type === 'number'
            ? { step: '1' }
            : undefined
      }
    />
  )
}
