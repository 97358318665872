import { Alert, Box, Typography } from '@mui/material'
import { Dayjs } from 'dayjs'
import { CSSProperties, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import KumocanButton from 'components/button'
import { notificationApi } from 'hooks/api/notification'
import { notificationStatus } from 'types/notification'
import { OrderWithAssign, SUSPENSION_TYPE_OPTIONS } from 'types/order'

// 日付を「YYYY年MM月DD日(曜日)」形式で表示する関数
const formatDateWithDay = (date: Dayjs) => {
  return date.format('YYYY年MM月DD日(ddd)') // 「YYYY年MM月DD日(土)」のように表示
}
// NOTE 配置の中止案内ページ
export default function AssignSuspentionPage() {
  const { id } = useParams<{ id: string }>()
  const [order, setOrder] = useState<OrderWithAssign>()
  const [isConfirmed, setIsConfirmed] = useState(false)
  const fetchData = useCallback(() => {
    notificationApi()
      .findById(id!)
      .then((res) => {
        if (res.data.status === notificationStatus.Notified) {
          notificationApi().readed(id!)
        }
      })
    notificationApi()
      .findOrderById(id!)
      .then((res) => {
        setOrder(res.data)
        setIsConfirmed(res.data.assign.suspensionConfirm === 1)
      })
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  // 配置済みの時のみ回答できるように

  const handleConfirmSuspension = () => {
    notificationApi()
      .suspensionConfirm(id!, 1) // 1を確認のステータスとして送信
      .then(() => {
        setIsConfirmed(true) // 確認済み状態に更新
      })
  }

  return (
    <Box sx={{ paddingBottom: '300px' }}>
      <Box>
        <Typography
          component={'div'}
          sx={{
            display: 'block',
            width: '85%',
            margin: '20px auto',
            fontSize: '16px',
            color: '#333',
            lineHeight: '1.6',
          }}
        >
          <Alert severity="warning">
            以下の案件はこれらの理由で中止になりました。ご確認ください
            <p style={{ marginTop: '10px' }}>
              中止理由:{' '}
              {
                SUSPENSION_TYPE_OPTIONS.find(
                  (x) => x.value === order?.suspensionType,
                )?.label
              }
            </p>
            <p>コメント: {order?.suspensionComment}</p>
            {isConfirmed && (
              <p style={{ marginTop: '10px', color: 'green' }}>
                確認済みです。
              </p>
            )}
          </Alert>

          <Box sx={cardStyle}>
            <div
              style={{
                textAlign: 'left',
                marginBottom: '10px',
                borderBottom: '1px dotted #ccc',
                paddingBottom: '10px',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>
                {order?.date && formatDateWithDay(order.date)}{' '}
                {order?.startTime?.format('HH:mm')}~
                {order?.endTime?.format('HH:mm')}
              </p>
            </div>

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '30% 70%',
                gap: '10px',
                marginBottom: '10px',
                borderBottom: '1px dotted #ccc',
                paddingBottom: '10px',
              }}
            >
              <p style={{ fontWeight: 'bold', fontSize: `12px` }}>契約先名</p>
              <p>{order?.clientName}</p>
            </div>

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '30% 70%',
                gap: '10px',
                marginBottom: '10px',
                borderBottom: '1px dotted #ccc',
                paddingBottom: '10px',
              }}
            >
              <p style={{ fontWeight: 'bold', fontSize: `12px` }}>住所</p>
              <p>{order?.address}</p>
            </div>

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '30% 70%',
                gap: '10px',
                marginBottom: '10px',
                borderBottom: '1px dotted #ccc',
                paddingBottom: '10px',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  whiteSpace: 'nowrap',
                }}
              >
                管制室からのコメント
              </p>
              <p
                style={{
                  whiteSpace: 'pre-wrap',
                  marginTop: '25px',
                }}
              >
                {order?.commentToSecurity}
              </p>
            </div>

            <div style={{ marginTop: '10px', textAlign: 'right' }}>
              {!isConfirmed && (
                <KumocanButton
                  onClick={handleConfirmSuspension}
                  style={submitButtonStyle}
                >
                  確認しました
                </KumocanButton>
              )}
            </div>
          </Box>
        </Typography>
      </Box>
    </Box>
  )
}

const cardStyle: CSSProperties = {
  width: '90%',
  margin: '20px auto',
  padding: '20px',
  borderRadius: '10px',
}

const submitButtonStyle: CSSProperties = {
  width: '100%',
  height: '50px',
  fontSize: '18px',
  margin: '20px auto 0',
  borderRadius: '10px',
}
