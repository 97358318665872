import { useLocation } from 'react-router-dom'

import { Notification } from 'types/notification'

export default function NotificationDetailPage() {
  const location = useLocation()
  const notification = location.state as Notification
  console.log('detail', notification.id)
  return (
    <div>
      <p>ID: {notification.id}</p> {/* 受け取ったIDを表示 */}
    </div>
  )
}
