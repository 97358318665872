import { Alert, Box } from '@mui/material'
import { CSSProperties } from 'react'

const NoSessionPage = () => {
  const alertStyle: CSSProperties = {
    margin: '10px auto 0',
    width: '80%',
  }
  return (
    <Box>
      <Alert sx={alertStyle} severity="error">
        認証情報でエラーが発生しました。運営会社に問い合わせてください
      </Alert>
    </Box>
  )
}

export default NoSessionPage
