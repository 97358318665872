import {
  LocationOn,
  StorefrontRounded,
  Summarize,
  WcRounded,
} from '@mui/icons-material'
import { Box, Typography, alpha } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import KumocanButton from 'components/button'
import KumocanDialog from 'components/dialog'
import KumocanSimpleTextareaAutosize from 'components/simpleTextArea'
import WvButton from 'components/wvButton'
import { assignApi } from 'hooks/api/assign'
import { securityApi } from 'hooks/api/security'
import { ASSIGN_STATUS, AssignWithContract } from 'types/assign'
import { PRIMARY_COLOR } from 'valiable'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export default function AssignPage(): JSX.Element {
  const query = useQuery()
  const assignId = query.get('assignId') || ''
  const [assign, setAssign] = useState<AssignWithContract>()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isReviewDialogOpen, setIsReviewDialogOpen] = useState<boolean>(false)
  const [review, setReview] = useState<number | null>(null)

  const today = dayjs().format('YYYY-MM-DD') // 表示用
  const scleentoday = dayjs().format('YYYY/MM/DD (dd)')
  const [currentTime, setCurrentTime] = useState(dayjs().format('HH:mm'))
  const fetchData = async () => {
    try {
      const assignResponse = await securityApi().filterAssignByDate(today)
      setAssign(assignResponse.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()

    const timer = setInterval(() => {
      setCurrentTime(dayjs().format('HH:mm')) // 毎分現在の時間を更新
    }, 60000)

    return () => clearInterval(timer)
  }, [today])

  const getNextStatus = (currentStatus: number) => {
    switch (currentStatus) {
      case ASSIGN_STATUS.AVAILABLE_FOR_WORK:
        return ASSIGN_STATUS.ATTENDANCE_REPORT
      case ASSIGN_STATUS.ATTENDANCE_REPORT:
        return ASSIGN_STATUS.ATTENDANCE_UP
      case ASSIGN_STATUS.ATTENDANCE_UP:
        return ASSIGN_STATUS.ATTENDANCE_DOWN
      case ASSIGN_STATUS.ATTENDANCE_DOWN:
        return ASSIGN_STATUS.ATTENDANCE_DOWN
      default:
        return ASSIGN_STATUS.COMPLETED
    }
  }

  const handleButtonClick = () => {
    handleOpen()
  }

  const submit = async () => {
    if (assign) {
      const nextStatus = getNextStatus(assign.status)
      try {
        await assignApi().updateStatus(assign.id, nextStatus)
        setAssign({ ...assign, status: nextStatus })
        setIsDialogOpen(false)
        if (nextStatus === ASSIGN_STATUS.ATTENDANCE_DOWN) {
          setIsReviewDialogOpen(true)
        }
      } catch (error) {
        console.error('Failed to update status:', error)
      }
    }
  }

  const handleSubmitReview = async () => {
    if (!review) {
      alert('満足度を選択してください！')
      return
    }
    if (!assign) {
      alert('割り当て情報が取得できていません。')
      return
    }
    try {
      await assignApi().updateSecurityReview(assign.id, review)
      setIsReviewDialogOpen(false)
    } catch (error) {
      alert('満足度の送信に失敗しました。')
    }
  }
  const throttledInputHandler = useRef(
    throttle((value: string) => {
      assignApi().updateSecurityNote(assignId, value)
    }, 2000),
  )

  const handleInput = (value: string) => {
    const trimed = value.trim()
    throttledInputHandler.current(trimed)
  }
  const handleOpen = () => {
    setIsDialogOpen(true)
  }
  const handleClose = () => {
    setIsDialogOpen(false)
    setIsReviewDialogOpen(false)
  }

  const toSecurityReport = () => {
    location.href = `/security-report?contract_detail_id=${assign?.contractDetail.id}&slide=right`
  }

  return (
    <Box
      sx={{
        width: '95%',
        margin: '10px auto 100px', // センター揃え
        overflowX: 'hidden', // 横スクロール禁止
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 3,
          padding: '20px 0',
          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            component={'span'}
            sx={{
              fontSize: '20px',
              fontWeight: 'bold',
              color: '#333',
            }}
          >
            {scleentoday}
          </Typography>
          <Typography
            component={'span'}
            sx={{
              fontSize: '84px',
              fontWeight: 'bold',
              color: '#333',
              letterSpacing: '0.05em',
            }}
          >
            {currentTime}
          </Typography>
        </Box>

        {!assign?.id ? (
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: '20px 20px',
              borderRadius: '12px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: '#757575',
              }}
            >
              案件なし
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                color: '#999999',
                marginTop: '8px',
              }}
            >
              現在、割り当てられた案件はありません。
            </Typography>
          </Box>
        ) : (
          <>
            <WvButton
              type="secondary"
              style={{
                width: '100%',
                fontSize: '64px',
                fontWeight: 'bold',
                letterSpacing: '0.2em',
                borderRadius: 10,
              }}
              onClick={handleButtonClick}
              status={assign?.status}
            ></WvButton>
            <Box
              sx={{
                background: alpha(PRIMARY_COLOR, 0.1),
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 1,
                padding: '10px 0',
                marginTop: '20px',
              }}
            >
              <Typography
                component={'span'}
                sx={{
                  fontSize: '18px',
                  color: '#757575',
                }}
              >
                {assign?.contractDetail.name}
              </Typography>
              <Typography
                component={'span'}
                sx={{
                  fontSize: '18px',
                  color: '#757575',
                }}
              >
                {dayjs(assign?.contractDetail.startTime).format('HH:mm')}~
                {dayjs(assign?.contractDetail.endTime).format('HH:mm')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <WvButton
                type="info"
                style={{
                  width: '100%',
                  height: '60px',
                  marginTop: '20px',
                }}
                onClick={() => {
                  if (assign?.address) {
                    // 緯度経度が0の場合は住所でGoogleマップを開く
                    location.href = `https://www.google.com/maps/search/?api=1&query=${assign?.address}&launch_app=true`
                  }
                }}
              >
                <LocationOn />
                <span
                  style={{
                    fontSize: 18,
                  }}
                >
                  現場の地図
                </span>
              </WvButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '20px',
              }}
            >
              {/* コンビニやトイレのボタン */}
              <WvButton
                type="secondary"
                style={{
                  width: '48%',
                  height: '60px',
                }}
                onClick={() => {
                  if (assign?.address) {
                    // 緯度経度が0の場合は住所でGoogleマップを開く
                    location.href = `https://www.google.com/maps/search/?api=1&query=トイレ near ${assign?.address}&launch_app=true`
                  }
                }}
              >
                <WcRounded />
                <span
                  style={{
                    fontSize: 18,
                  }}
                >
                  トイレ
                </span>
              </WvButton>
              <WvButton
                type="secondary"
                style={{
                  width: '48%',
                  height: '60px',
                }}
                onClick={() => {
                  if (assign?.address) {
                    // 緯度経度が0の場合は住所でGoogleマップを開く
                    location.href = `https://www.google.com/maps/search/?api=1&query=コンビニ near${assign?.address}&launch_app=true`
                  }
                }}
              >
                <StorefrontRounded />
                <span
                  style={{
                    fontSize: 18,
                  }}
                >
                  コンビニ
                </span>
              </WvButton>
            </Box>
            {assign?.isLeader === true && (
              <WvButton
                type="securityReport"
                style={{
                  width: '100%',
                  height: '60px',
                  marginTop: '20px',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
                onClick={toSecurityReport}
              >
                <Summarize />
                警備報告書
              </WvButton>
            )}
            <KumocanSimpleTextareaAutosize
              id="text-area"
              onInput={handleInput}
              placeHolder="警備員用メモ"
              defaultValue={assign?.securityNote}
              style={{
                width: '96%',
                height: '100px',
                fontSize: '20px',
                padding: '5px',
                marginTop: '20px',
              }}
            />
            <KumocanDialog open={isDialogOpen} handleClose={handleClose}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  gap: 5,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  更新します。よろしいですか？
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <KumocanButton
                    onClick={handleClose}
                    variant="outlined"
                    style={{
                      minWidth: '100px',
                      height: '50px',
                      fontSize: '18px',
                    }}
                  >
                    キャンセル
                  </KumocanButton>
                  <KumocanButton
                    onClick={submit}
                    style={{
                      minWidth: '100px',
                      height: '50px',
                      fontSize: '18px',
                    }}
                  >
                    OK
                  </KumocanButton>
                </Box>
              </Box>
            </KumocanDialog>
            <KumocanDialog open={isReviewDialogOpen} handleClose={handleClose}>
              <Box
                sx={{
                  padding: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                >
                  Q. 本日のお仕事の満足度は？
                </Typography>

                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#757575',
                    marginBottom: 1,
                  }}
                >
                  ※現場のお客様や他の警備員には一切共有しないのでご安心ください。
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  {[1, 2, 3, 4].map((value) => (
                    <KumocanButton
                      key={value}
                      onClick={() => setReview(value)} // 選択された値を状態にセット
                      variant={review === value ? 'contained' : 'outlined'}
                      style={{
                        minWidth: '50px',
                        height: '50px',
                        fontSize: '16px',
                      }}
                    >
                      {value}
                    </KumocanButton>
                  ))}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    paddingX: 2,
                    marginTop: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '15px',
                      color: '#757575',
                    }}
                  >
                    大変不満
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      color: '#757575',
                    }}
                  >
                    大変満足
                  </Typography>
                </Box>

                <KumocanButton
                  onClick={handleSubmitReview}
                  variant="contained"
                  style={{
                    marginTop: 2,
                    backgroundColor: review ? '#14b7ae' : '#e0e0e0',
                    color: review ? '#ffffff' : '#757575',
                    minWidth: '100px',
                    height: '50px',
                    fontSize: '18px',
                  }}
                  disabled={!review}
                >
                  送信する
                </KumocanButton>
              </Box>
            </KumocanDialog>
          </>
        )}
      </Box>
    </Box>
  )
}

function throttle(func: (...args: any[]) => void, limit: number) {
  let lastFunc: NodeJS.Timeout
  let lastRan: number

  return function (...args: any[]) {
    if (!lastRan) {
      func(...args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(
        function () {
          if (Date.now() - lastRan >= limit) {
            func(...args)
            lastRan = Date.now()
          }
        },
        limit - (Date.now() - lastRan),
      )
    }
  }
}
