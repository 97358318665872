import { Replay } from '@mui/icons-material'
import { Box } from '@mui/material'
import React, {
  useState,
  useEffect,
  useCallback,
  ReactNode,
  CSSProperties,
} from 'react'

interface PullToRefreshProps {
  children: ReactNode
  onRefresh: () => void
}

const REFRESH_TARGET = 100

const iconStyle: CSSProperties = {
  width: '100%',
  margin: 'auto',
  fontSize: '28px',
}

const PullToRefresh: React.FC<PullToRefreshProps> = ({
  children,
  onRefresh,
}) => {
  const [pulling, setPulling] = useState(false)
  const [startY, setStartY] = useState(0)
  const [currentY, setCurrentY] = useState(0)
  const [shouldRefresh, setShouldRefresh] = useState(false)

  const onTouchStart = useCallback((e: TouchEvent) => {
    setStartY(e.touches[0].clientY)
    setCurrentY(e.touches[0].clientY)
    setPulling(true)
  }, [])

  const isOverTarget = pulling && startY + REFRESH_TARGET < currentY

  const onTouchMove = useCallback(
    (e: TouchEvent) => {
      setCurrentY(e.touches[0].clientY)
    },
    [isOverTarget],
  )

  const onTouchEnd = useCallback(() => {
    if (isOverTarget) {
      setShouldRefresh(true)
    }
    setPulling(false)
  }, [pulling, startY, currentY])

  useEffect(() => {
    if (!pulling && shouldRefresh) {
      onRefresh()
      setShouldRefresh(false)
    }
  }, [pulling, shouldRefresh, onRefresh])

  useEffect(() => {
    window.addEventListener('touchstart', onTouchStart)
    window.addEventListener('touchmove', onTouchMove)
    window.addEventListener('touchend', onTouchEnd)

    return () => {
      window.removeEventListener('touchstart', onTouchStart)
      window.removeEventListener('touchmove', onTouchMove)
      window.removeEventListener('touchend', onTouchEnd)
    }
  }, [onTouchStart, onTouchMove, onTouchEnd])

  return (
    <Box>
      {isOverTarget && <Replay sx={iconStyle} color="primary" />}
      {children}
    </Box>
  )
}

export default PullToRefresh
