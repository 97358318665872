import {
  Alert,
  Badge,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
} from '@mui/material'
import { useEffect, useState, useCallback, Fragment } from 'react'

import PullToRefresh from 'components/pullToRefresh'
import { notificationApi } from 'hooks/api/notification'
import {
  Notification,
  contentType,
  notificationStatus,
  getBadgeStyle,
  getBadgeLabel,
} from 'types/notification'
import { PRIMARY_COLOR } from 'valiable'

export default function NotificationPage(): JSX.Element {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [isUnread, setIsUnread] = useState<boolean>(true)
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const fetchData = useCallback(() => {
    notificationApi()
      .getAll(isUnread)
      .then((res) => {
        setNotifications(res.data)
      })
  }, [isUnread])

  useEffect(() => {
    fetchData()
  }, [isUnread, fetchData])

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
    setIsUnread(newValue === 0) // 未読が0, 全てが1
  }

  const handleListItemClick = (notification: Notification) => {
    switch (notification.contentType) {
      case contentType.AssignConfirmation:
        location.href = `/notification/${notification.id}/assign-confirm?slide=right`
        break
      case contentType.AssignSuspension:
        location.href = `/notification/${notification.id}/assign-suspention?slide=right`
        break
      default:
        console.log('Notification status is not recognized.')
    }
  }

  const refresh = () => {
    fetchData()
  }

  return (
    <Box>
      <Box sx={{ width: '100%', marginTop: '10px' }}>
        <Box
          sx={{
            width: '100%',
            borderBottom: `2px solid ${PRIMARY_COLOR}`, // 画面全体に下線を引く
            position: 'relative',
          }}
        >
          <Box
            sx={{
              width: '80%',
              margin: '0 auto',
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              sx={{
                '& .MuiTab-root': {
                  backgroundColor: '#e6e6e6',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  borderRadius: '10px 10px 0 0',
                  minHeight: '34px',
                  height: '34px',
                  color: 'grey !important',
                },
                borderRadius: '10px 10px 0 0',
                '& .Mui-selected': {
                  backgroundColor: PRIMARY_COLOR,
                  borderRadius: '10px 10px 0 0',
                  color: 'white !important',
                },
                minHeight: '34px',
              }}
              TabIndicatorProps={{
                sx: { display: 'none' }, // 標準の下線は非表示
              }}
              variant="fullWidth"
            >
              <Tab
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span>未読</span>
                    <Badge
                      badgeContent={
                        notifications.filter(
                          (n) => n.status !== notificationStatus.AlreadyRead,
                        ).length
                      }
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: 'red',
                          color: 'white',
                          right: '-20px',
                        },
                      }}
                      max={99}
                    />
                  </Box>
                }
              />
              <Tab label="全て" sx={{ marginLeft: '10px' }} />
            </Tabs>
          </Box>
        </Box>
      </Box>

      <PullToRefresh onRefresh={refresh}>
        {notifications.length === 0 && (
          <Alert
            severity="info"
            sx={{
              margin: '10px auto 0',
              width: '80%',
            }}
          >
            お知らせはありません
          </Alert>
        )}
        <List>
          {notifications.map((notification, index) => (
            <Fragment key={notification.id}>
              <ListItemButton
                key={notification.id}
                onClick={() => handleListItemClick(notification)}
              >
                <Box
                  sx={{
                    width: '50px',
                    height: '45px',
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    alignItems: 'center',
                    borderRadius: '4px',
                    backgroundColor:
                      notification.contentType === contentType.AssignSuspension
                        ? '#FF4081'
                        : getBadgeStyle(notification.shiftType).backgroundColor, // 中止の場合は赤色、それ以外はシフトタイプのスタイル
                    color: 'white',
                  }}
                >
                  {notification.contentType === contentType.AssignSuspension
                    ? '中止'
                    : getBadgeLabel(notification.shiftType)}
                </Box>

                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      fontSize: '20px',
                      fontWeight: 'bold',
                      color:
                        notification.contentType ===
                        contentType.AssignSuspension
                          ? 'red'
                          : '#666666',
                    },
                  }}
                  primary={notification.title}
                  secondary={`送信日時: ${notification.sentAt.format('YYYY-MM-DD HH:mm')}`}
                  sx={{ marginLeft: '12px', width: '90%' }}
                />

                {notification.status !== notificationStatus.AlreadyRead && (
                  <Box
                    sx={{
                      width: '15px',
                      height: '15px',
                      backgroundColor: 'red',
                      borderRadius: '50%',
                    }}
                  />
                )}
              </ListItemButton>

              {index !== notifications.length - 1 && <Divider />}
            </Fragment>
          ))}
        </List>
      </PullToRefresh>
    </Box>
  )
}
