import { Dayjs } from 'dayjs'

import { Client } from './client'
import { Option } from './input'

export interface ContractDetail {
  id: string
  contractId: string
  contractBillingId: string
  name: string
  startDate: Dayjs
  endDate: Dayjs
  postalCode: string
  address: string
  teamName: string
  startTime: Dayjs
  endTime: Dayjs
  numberOfSecurities: number
  businessDivision: number
  contract?: Contract
  contractBilling?: ContractBilling
  nearestStation?: string
}

export interface ContractBilling {
  id: string
  title: string
  contractId: string
  shiftType: number
  price: number
  qualifications: number[]
  overTimePrice: number
  underTimePrice: number
}

export interface Contract {
  id: string
  companyBranchId: string
  name: string
  clientId: string
  phoneNumber: string
  faxNumber: string
  contractedAt: Dayjs
  madeAt: Dayjs
  contractDetails: ContractDetail[]
  contractBillings: ContractBilling[]
  client?: Client
  status: number
}
export const BUSINESS_DIVISION = {
  FACILITY: 1,
  PATROL: 2,
  SECURITY: 3,
  TRAFFIC_GUIDANCE: 4,
  CROWD: 5,
  TRANSPORT: 6,
  PERSONAL: 7,
  TRAIN_WATCH: 8,
}

export const BUSINESS_DIVISION_OPTION: Option[] = [
  {
    label: '施設',
    value: BUSINESS_DIVISION.FACILITY,
  },
  {
    label: '巡回',
    value: BUSINESS_DIVISION.PATROL,
  },
  {
    label: '保安',
    value: BUSINESS_DIVISION.SECURITY,
  },
  {
    label: '交通誘導',
    value: BUSINESS_DIVISION.TRAFFIC_GUIDANCE,
  },
  {
    label: '雑踏',
    value: BUSINESS_DIVISION.CROWD,
  },
  {
    label: '運搬',
    value: BUSINESS_DIVISION.TRANSPORT,
  },
  {
    label: '身辺',
    value: BUSINESS_DIVISION.PERSONAL,
  },
  {
    label: '列車見張',
    value: BUSINESS_DIVISION.TRAIN_WATCH,
  },
]
