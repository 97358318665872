import AssignConfirmPage from 'pages/notification/assignConfirm/page'
import AssignSuspentionPage from 'pages/notification/assignSuspention/page'
import NotificationDetailPage from 'pages/notification/detail/page'
import NotificationPage from 'pages/notification/page'

export const notificationsRoutes = [
  { path: '/notification', element: <NotificationPage /> },
  { path: '/notification/:id', element: <NotificationDetailPage /> },
  {
    path: '/notification/:id/assign-confirm',
    element: <AssignConfirmPage />,
  },
  {
    path: '/notification/:id/assign-suspention',
    element: <AssignSuspentionPage />,
  },
]
