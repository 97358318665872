import { AxiosResponse } from 'axios'
import { Dayjs } from 'dayjs'

import { _axios } from 'hooks/axios'
import { NewDate } from 'hooks/helper/date'
import { SecurityReport, SecurityReportResult } from 'types/securityReport'

const FIND_BY_DETAIL_ID_DATE = `/security-reports`
const UPDATE = (id: string) => `/security-reports/${id}`

export const securityReportApi = () => {
  const findByDetailIdAndDate = async (
    id: string,
    date: Dayjs,
  ): Promise<AxiosResponse<SecurityReport>> => {
    const params = {
      contractDetailId: id,
      date: date.format('YYYY-MM-DD'),
    }
    return await _axios()
      .get(FIND_BY_DETAIL_ID_DATE, { params })
      .then((res) => {
        res.data = {
          ...res.data.securityReport,
          date: NewDate(res.data.date),
          securityReportResults: res.data.securityReportResults.map(
            (r: SecurityReportResult) => {
              return {
                ...r,
                startTime: NewDate(r.startTime),
                endTime: NewDate(r.endTime),
              }
            },
          ),
        }
        return res
      })
  }
  const sign = async (
    id: string,
    responsiblePersonSignature: string,
    note: string,
    reportResults: SecurityReportResult[],
  ): Promise<AxiosResponse<string>> => {
    const params = {
      responsiblePersonSignature: responsiblePersonSignature,
      note: note,
      securityReportResults: reportResults,
    }
    return await _axios()
      .put(UPDATE(id), params)
      .then((res) => {
        res.data = res.data
        return res
      })
  }
  return {
    findByDetailIdAndDate,
    sign,
  }
}
