import { Dayjs } from 'dayjs'

import { Assign } from './assign'
import { ContractDetail } from './contract'

export interface FindAssignByIdRes {
  volume: number
  order: OrderWithAssign
  replyFomat: string
  otherAssigns: Assign
}

export interface OrderWithAssign {
  id: string
  contractDetailName: string
  date: Dayjs
  volume: number
  address: string
  lat: number
  lng: number
  nearestStation: string
  startTime: Dayjs
  endTime: Dayjs
  clientName: string
  commentToSecurity: string
  suspensionType: number
  suspensionComment: string
  assign: Assign
  otherAssigns: Assign[]
}

export interface OrderWithContractDetail {
  id: string
  contractDetail: ContractDetail
  address: string
  lat: number
  lng: number
  shiftType: number
}

const SUSPENSION_TYPE = {
  Weather: 1, // 天気(悪天候など)
  ProjectCancel: 2, // 案件自体のキャンセル
  // その他
  Other: 100,
}

export const SUSPENSION_TYPE_OPTIONS = [
  {
    label: '悪天候',
    value: SUSPENSION_TYPE.Weather,
  },
  {
    label: '案件自体のキャンセル',
    value: SUSPENSION_TYPE.ProjectCancel,
  },
  {
    label: 'その他',
    value: SUSPENSION_TYPE.Other,
  },
]
