import { AxiosResponse } from 'axios'
import { Dayjs } from 'dayjs'

import { _axios } from 'hooks/axios'
import { Shift } from 'types/shift'

const FILTER_BY_DATE = `/shifts`

export const shiftApi = () => {
  const findByDate = async (
    start: Dayjs,
    end: Dayjs,
  ): Promise<AxiosResponse<Shift[]>> => {
    const params = {
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD'),
    }
    return await _axios()
      .get(FILTER_BY_DATE, { params })
      .then((res) => {
        res.data = res.data.shifts
        return res
      })
  }

  return {
    findByDate,
  }
}
