import { AxiosResponse } from 'axios'

import { _axios } from 'hooks/axios'
import { AssignWithContract } from 'types/assign'

const FILTER_ASSIGN_BY_DATE = `/securities/assign`

export const securityApi = () => {
  const filterAssignByDate = async (
    date: string,
  ): Promise<AxiosResponse<AssignWithContract>> => {
    return await _axios()
      .get(FILTER_ASSIGN_BY_DATE, {
        params: {
          date: date,
        },
      })
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  return {
    filterAssignByDate,
  }
}
