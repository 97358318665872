import { ContractDetail } from './contract'
import { Notification } from './notification'
import { OrderWithContractDetail } from './order'
import { Security } from './security'

export interface Assign {
  id: string
  orderId: string
  status: number
  replyWhenUnavailable: string
  replyWhenAvailable: string
  suspensionConfirm: number
  note: string
  securityNote: string
  security: Security
}

export interface AssignMember {
  assignId: string
  security: Security
}

export interface AssignWithOrder {
  id: string
  status: number
  replyWhenUnavailable: string
  replyWhenAvailable: string
  note: string
  securityNote: string
  notification?: Notification
  order: OrderWithContractDetail
  members: AssignMember[]
}

export interface AssignWithContract {
  id: string
  orderId: string
  date: string
  status: number
  address: string
  contractDetail: ContractDetail
  securityNote: string
  isLeader: boolean
}

export const ASSIGN_STATUS = {
  COMPLETED: 0, // 配置済み
  DELETED: 10, // 削除済み
  NOTIFIED: 20, // 通知済み
  AVAILABLE_FOR_WORK: 21, // 出勤可能
  UNAVAILABLE_FOR_WORK: 25, // 出勤不可能
  ATTENDANCE_REPORT: 30, // 出勤報告
  ATTENDANCE_UP: 40, // 上番
  ATTENDANCE_DOWN: 50, // 下番
}
