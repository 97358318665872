'use client'

import Button from '@mui/material/Button'
import { CSSProperties } from 'react'

interface Props {
  children: React.ReactNode
  buttonType?: 'button' | 'submit'
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'

  variant?: 'text' | 'contained' | 'outlined' | undefined
  onClick?: () => void
  style?: CSSProperties
  disabled?: boolean
  className?: string
}

export default function KumocanButton(props: Props) {
  const {
    children,
    buttonType = 'button',
    color = 'primary',
    variant = 'contained',
    onClick,
    style,
    disabled = false,
    className,
  } = props

  const buttonStyle: CSSProperties = {
    padding: '4px 10px',
    minWidth: '40px',
    ...style,
  }

  return (
    <Button
      variant={variant}
      color={color}
      onClick={onClick}
      type={buttonType}
      style={buttonStyle}
      disabled={disabled}
      className={className}
    >
      {children}
    </Button>
  )
}
