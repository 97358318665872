import { Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import KumocanSimpleTextareaAutosize from 'components/simpleTextArea'
import { assignApi } from 'hooks/api/assign'
import { Assign } from 'types/assign'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export default function TopPage(): JSX.Element {
  const query = useQuery()
  const assignId = query.get('assignId') || ''
  const [assign, setAssign] = useState<Assign>()

  useEffect(() => {
    if (!assignId) return
    assignApi()
      .findById(assignId)
      .then((res) => {
        setAssign(res.data)
      })
  }, [])

  const throttledInputHandler = useRef(
    throttle((value: string) => {
      assignApi().updateSecurityNote(assignId, value)
    }, 2000),
  ) // 2秒間隔でスロットリング

  const handleInput = (value: string) => {
    const trimed = value.trim()
    throttledInputHandler.current(trimed)
  }

  return (
    <Box sx={{ width: '99%', margin: '10px auto 0px' }}>
      <KumocanSimpleTextareaAutosize
        id="text-area"
        onInput={handleInput}
        placeHolder="警備員用メモ"
        defaultValue={assign?.securityNote}
        style={{
          width: '96%',
          height: '100px',
          fontSize: '20px',
          padding: '5px',
        }}
      />
    </Box>
  )
}

function throttle(func: (...args: any[]) => void, limit: number) {
  let lastFunc: NodeJS.Timeout
  let lastRan: number

  return function (...args: any[]) {
    if (!lastRan) {
      func(...args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(
        function () {
          if (Date.now() - lastRan >= limit) {
            func(...args)
            lastRan = Date.now()
          }
        },
        limit - (Date.now() - lastRan),
      )
    }
  }
}
