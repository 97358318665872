import { TimePicker } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import { Control, Controller } from 'react-hook-form'

interface Props {
  name: string
  control: Control<any>
  required?: boolean
}

export default function KanseiTimePicker(props: Props) {
  const { name, control } = props

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (control && control._defaultValues) {
      let value = control._defaultValues
      const paths = name.split('.')
      for (const path of paths) {
        value = value?.[path]
      }
      // TODO dev環境の場合はerrorを吐くようにしたい
      setIsLoaded(true)
    }
  }, [control, setIsLoaded, name])

  if (!isLoaded) {
    return null
  }

  const value = control._defaultValues[name]
    ? control._defaultValues[name]
    : null

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field }) => (
        <TimePicker
          {...field}
          views={['hours', 'minutes']}
          localeText={{
            cancelButtonLabel: 'キャンセル',
            okButtonLabel: '決定',
            toolbarTitle: '時刻選択',
          }}
          value={field.value}
          defaultValue={field.value}
          inputRef={field.ref}
          onChange={(newDate) => {
            field.onChange(newDate)
          }}
        />
      )}
    />
  )
}
