import { Option } from './input'

export type Security = {
  id: string
  originalCode: string
  firstName: string
  lastName: string
  firstNameKana: string
  lastNameKana: string
  birthday: string
  age?: number
  gender: number
  qualifications: number[]
}
export const QUALIFICATION = {
  FACILITY_SECURITY_SERVICES_CERTIFICATION_LEVEL_1: 1 << 0,
  FACILITY_SECURITY_SERVICES_CERTIFICATION_LEVEL_2: 1 << 1,
  AIRPORT_SECURITY_GUARD_CERTIFICATION_LEVEL_1: 1 << 2,
  AIRPORT_SECURITY_GUARD_CERTIFICATION_LEVEL_2: 1 << 3,
  TRAFFIC_GUIDANCE_AND_SECURITY_SERVICES_CERTIFICATION_LEVEL_1: 1 << 4,
  TRAFFIC_GUIDANCE_AND_SECURITY_SERVICES_CERTIFICATION_LEVEL_2: 1 << 5,
  CROWD_SECURITY_SERVICES_CERTIFICATION_LEVEL_1: 1 << 6,
  CROWD_SECURITY_SERVICES_CERTIFICATION_LEVEL_2: 1 << 7,
  VALUABLES_TRANSPORTATION_SECURITY_SERVICES_CERTIFICATION_LEVEL_1: 1 << 8,
  VALUABLES_TRANSPORTATION_SECURITY_SERVICES_CERTIFICATION_LEVEL_2: 1 << 9,
  SECURITY_SERVICE_CERTIFICATION_FOR_TRANSPORTATION_OF_NUCLEAR_FUEL_MATERIALS_AND_OTHER_HAZARDOUS_MATERIALS_LEVEL_1:
    1 << 10,
  SECURITY_SERVICE_CERTIFICATION_FOR_TRANSPORTATION_OF_NUCLEAR_FUEL_MATERIALS_AND_OTHER_HAZARDOUS_MATERIALS_LEVEL_2:
    1 << 11,
}

export const QUALIFICATION_OMISSION_OPTIONS: Option[] = [
  {
    label: '施設1級',
    value: QUALIFICATION.FACILITY_SECURITY_SERVICES_CERTIFICATION_LEVEL_1,
  },
  {
    label: '施設2級',
    value: QUALIFICATION.FACILITY_SECURITY_SERVICES_CERTIFICATION_LEVEL_2,
  },
  {
    label: '空港1級',
    value: QUALIFICATION.AIRPORT_SECURITY_GUARD_CERTIFICATION_LEVEL_1,
  },
  {
    label: '空港2級',
    value: QUALIFICATION.AIRPORT_SECURITY_GUARD_CERTIFICATION_LEVEL_2,
  },
  {
    label: '交通1級',
    value:
      QUALIFICATION.TRAFFIC_GUIDANCE_AND_SECURITY_SERVICES_CERTIFICATION_LEVEL_1,
  },
  {
    label: '交通2級',
    value:
      QUALIFICATION.TRAFFIC_GUIDANCE_AND_SECURITY_SERVICES_CERTIFICATION_LEVEL_2,
  },
  {
    label: '雑踏1級',
    value: QUALIFICATION.CROWD_SECURITY_SERVICES_CERTIFICATION_LEVEL_1,
  },
  {
    label: '雑踏2級',
    value: QUALIFICATION.CROWD_SECURITY_SERVICES_CERTIFICATION_LEVEL_2,
  },
  {
    label: '貴重品1級',
    value:
      QUALIFICATION.VALUABLES_TRANSPORTATION_SECURITY_SERVICES_CERTIFICATION_LEVEL_1,
  },
  {
    label: '貴重品2級',
    value:
      QUALIFICATION.VALUABLES_TRANSPORTATION_SECURITY_SERVICES_CERTIFICATION_LEVEL_2,
  },
  {
    label: '核燃1級',
    value:
      QUALIFICATION.SECURITY_SERVICE_CERTIFICATION_FOR_TRANSPORTATION_OF_NUCLEAR_FUEL_MATERIALS_AND_OTHER_HAZARDOUS_MATERIALS_LEVEL_1,
  },
  {
    label: '核燃2級',
    value:
      QUALIFICATION.SECURITY_SERVICE_CERTIFICATION_FOR_TRANSPORTATION_OF_NUCLEAR_FUEL_MATERIALS_AND_OTHER_HAZARDOUS_MATERIALS_LEVEL_2,
  },
]
