'use client'

import Button from '@mui/material/Button'
import { CSSProperties, ReactNode } from 'react'

import { ASSIGN_STATUS } from 'types/assign'
import { PRIMARY_COLOR } from 'valiable'

type ColorType = 'primary' | 'secondary' | 'info' | 'securityReport'

interface Props {
  children?: ReactNode
  type: ColorType
  variant?: 'text' | 'contained' | 'outlined' | undefined
  buttonType?: 'button' | 'submit'
  onClick?: () => void
  style?: CSSProperties
  disabled?: boolean
  status?: number
}

export default function WvButton(props: Props) {
  const {
    children,
    type = 'primary',
    variant = 'contained',
    buttonType = 'button',
    onClick,
    style,
    disabled = false,
    status,
  } = props

  const getColorStyles = (type: ColorType, disabled: boolean) => {
    if (disabled) {
      return { color: '#D6D6D6', backgroundColor: '#F3F3F3' }
    }
    if (
      status === ASSIGN_STATUS.ATTENDANCE_REPORT ||
      status === ASSIGN_STATUS.ATTENDANCE_UP
    ) {
      return { color: '#FFFFFF', backgroundColor: '#14b7ae' }
    }
    switch (type) {
      case 'primary':
        return { color: '#FFFFFF', backgroundColor: PRIMARY_COLOR }
      case 'secondary':
        return { color: '#757575', backgroundColor: '#FFFFFF' }
      case 'info':
        return { color: PRIMARY_COLOR, backgroundColor: '#FFFFFF' }
      case 'securityReport':
        return { color: '#FFFFFF', backgroundColor: '#B29C99' }
      default:
        return { color: '#FFFFFF', backgroundColor: PRIMARY_COLOR }
    }
  }

  const getStatusText = (status?: number) => {
    switch (status) {
      case ASSIGN_STATUS.AVAILABLE_FOR_WORK:
        return '出発報告'
      case ASSIGN_STATUS.ATTENDANCE_REPORT:
        return '上番'
      case ASSIGN_STATUS.ATTENDANCE_UP:
        return '下番'
      case ASSIGN_STATUS.ATTENDANCE_DOWN:
        return '下番'
      default:
        return 'ステータス不明'
    }
  }
  const isDisabled = disabled || status === ASSIGN_STATUS.ATTENDANCE_DOWN
  const { color, backgroundColor } = getColorStyles(type, isDisabled)

  return (
    <Button
      variant={variant}
      onClick={onClick}
      type={buttonType}
      style={{
        ...style,
        color,
        backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disabled={isDisabled}
    >
      {status ? getStatusText(status) : children}
    </Button>
  )
}
